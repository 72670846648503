import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  selector: 'app-dashboard-weather-forecast',
  standalone: true,
  imports: [MatIconModule, FlexLayoutModule],
  templateUrl: './dashboard-weather-forecast.component.html',
  styleUrl: './dashboard-weather-forecast.component.scss',
})
export class DashboardWeatherForecastComponent {}
