import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, switchMap, takeUntil, tap } from 'rxjs';
import { SetPassword } from './models/set-password.model';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmPasswordDirective } from '../../directives/confirm-password.directive';
import { PasswordPolicyDirective } from '../../directives/password-policy.directive';
import { ApiToken } from '../../models/login-response.model';
import { AuthService } from '../../services/auth.service';
import { UserPasswordService } from '../../services/user-password.service';
import { AuthConstants } from '../../utils/constants/auth-constants';
import { LoginPathConstants, DashboardPathConstants } from '../../utils/constants/component-path-constants';
import { BaseComponent } from '../base-component/base-component.component';
import { LogoComponent } from '../logo/logo.component';

@Component({
  selector: 'app-set-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    LogoComponent,
    PasswordPolicyDirective,
    ConfirmPasswordDirective,
    MatIconModule,
  ],
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.scss',
})
export class SetPasswordComponent extends BaseComponent {
  @ViewChild('password') password!: NgModel;
  @ViewChild('confirmPassword') confirmPassword!: NgModel;
  public model: SetPassword = {};
  public errorMessages: string[] | null = null;
  public email: string | null = null;
  public isResetPassword: boolean | null = null;
  public hidePassword = true;
  public hideConfirmPassword = true;

  constructor(
    private userPasswordService: UserPasswordService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.isResetPassword = params['reset'];
      this.model.emailAddress = this.email!;
    });
  }

  public setPassword() {
    if (this.validate()) {
      if (this.isResetPassword) {
        this.userPasswordService
          .resetPassword(this.model)
          .subscribe((res: any) => {
            if (res.value.succeeded) {
              this.router.navigateByUrl(LoginPathConstants.LOGIN);
            }
          });
      } else {
        this.userPasswordService
          .setPassword(this.model)
          .pipe(
            switchMap((res: any) => {
              if (res.value.succeeded) {
                return this.authService.login({
                  email: this.email!,
                  password: this.model.password,
                });
              } else {
                return EMPTY;
              }
            }),
            tap((response: ApiToken) => {
              if (response && response.token) {
                localStorage.setItem(AuthConstants.JWT_TOKEN, response.token);
              } else {
                throw new Error('Login failed: Token missing');
              }
            }),
            tap(() => {
              this.router.navigateByUrl(
                DashboardPathConstants.DASHBOARD_OVERVIEW,
              );
            }),
            takeUntil(this.destroy$),
          )
          .subscribe({
            complete: () => {
              this.model = {};
            },
          });
      }
    }
  }

  public validate(): boolean {
    return !!this.password.valid && !!this.confirmPassword.valid;
  }
}
