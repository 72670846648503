import { Injectable } from '@angular/core';
import { environment } from '../../../../fms-web/src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Login } from '../components/login/models/login.model';
import { DataService } from './data.service';
import { AuthConstants } from '../utils/constants/auth-constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginPathConstants } from '../utils/constants/component-path-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends DataService {
  private helper = new JwtHelperService();

  constructor(
    public override http: HttpClient,
    private router: Router,
  ) {
    super(http, `${environment.authApiUrlPart}/Authenticate`);
  }

  public login(userCredentials: Login): Observable<any> {
    const path = `login`;
    return super.post<any>(path, userCredentials);
  }

  public logout(): void {
    localStorage.removeItem(AuthConstants.JWT_TOKEN);
    this.router.navigateByUrl(LoginPathConstants.LOGIN);
  }

  public isLoggedIn(): boolean {
    const token = this.getJwt();

    if (token) {
      if (this.isTokenExpired()) {
        this.logout();
        return false;
      }
    }
    return token !== null;
  }

  public isTokenExpired(): boolean {
    const token = this.getJwt();

    if (!token) {
      return true;
    }

    return this.helper.isTokenExpired(token);
  }

  public getJwt(): string | null {
    return localStorage.getItem(AuthConstants.JWT_TOKEN);
  }
}
