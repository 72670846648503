import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  private helper = new JwtHelperService();

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const requiredPermissions = route.data['permissions'] as string[];

    const token = localStorage.getItem('auth_token');
    if (token) {
      try {
        const decodedToken = this.helper.decodeToken(token);
        const userPermissions: string[] = decodedToken.Policy || [];

        const hasPermission = requiredPermissions?.some((permission) =>
          userPermissions.includes(permission)
        );

        if (hasPermission) {
          return true;
        } else {
          this.router.navigate(['/dashboard']);
          return false;
        }
      } catch (error) {
        console.error('Error decoding token', error);
        this.router.navigate(['/dashboard']);
        return false;
      }
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}
