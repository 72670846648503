import { Directive, Input, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, FormControl, ValidatorFn } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appConfirmPassword]',
    standalone: true,
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ConfirmPasswordDirective,
            multi: true,
        },
    ],
})
export class ConfirmPasswordDirective implements Validator, OnInit, OnDestroy {
    @Input('appConfirmPassword') passwordFieldName!: string;
    private passwordControl!: FormControl;
    private subscription: Subscription | null = null;
    private confirmPasswordControl: AbstractControl | null = null;

    ngOnInit(): void {
        // Nothing to initialize here yet
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // Assign the confirmPasswordControl so we can use it in ngOnInit to set up the subscription
        if (!this.confirmPasswordControl) {
            this.confirmPasswordControl = control;

            if (control.parent && control.parent.get(this.passwordFieldName)) {
                this.passwordControl = control.parent.get(this.passwordFieldName) as FormControl;

                // Subscribe to valueChanges on the password control
                this.subscription = this.passwordControl.valueChanges.subscribe(() => {
                    // Revalidate the confirm password field whenever the password changes
                    control.updateValueAndValidity();
                });
            }
        }

        const confirmPassword = control.value;

        if (this.passwordControl && confirmPassword !== this.passwordControl.value) {
            return { passwordMismatch: 'Password and confirm password do not match.' };
        }

        return null;
    }

    ngOnDestroy(): void {
        // Unsubscribe to avoid memory leaks
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
