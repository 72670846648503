<div class="mission-container">
  <div class="missions-header">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1rem">
      <mat-icon class="missions-icon" svgIcon="missions_icon"></mat-icon>
      <h3 class="missions-title">SCHEDULED MISSIONS</h3>
    </div>
    <div class="btn-container">
      <button mat-button class="today-btn">
        <mat-icon svgIcon="dashboard_today_icon"></mat-icon>
        <span>Today</span>
      </button>
      <button mat-flat-button class="add-btn">
        Add new mission
        <mat-icon
          iconPositionEnd
          svgIcon="plus_icon"
          class="add-icon"
        ></mat-icon>
      </button>
    </div>
  </div>

  <div class="cards-wrapper">
    <div class="card" *ngFor="let mission of visibleMissions; let i = index">
      <div class="mission-details">
        <h5>UNDER CONSTRUCTION</h5>
      </div>
    </div>
  </div>

  <div class="navigation-buttons">
    <button mat-button (click)="previousPage()">
      <mat-icon svgIcon="chevron_left_icon"></mat-icon>
    </button>
    <button mat-button (click)="nextPage()">
      <mat-icon svgIcon="chevron_right_icon"></mat-icon>
    </button>
  </div>
</div>
