import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { environment } from '../../../../fms-web/src/environments/environment';
import { Observable } from 'rxjs';
import { ObjectResult } from '../models/object-result.model';
import { ResetPasswordNotification } from '../components/forgot-password/models/reset-password-notification.model';
import { ResetPassword } from '../components/set-password/models/reset-password.model';
import { SetPassword } from '../components/set-password/models/set-password.model';

@Injectable({
  providedIn: 'root',
})
export class UserPasswordService extends DataService {
  constructor(public override http: HttpClient) {
    super(http, `${environment.usersPasswordApiUrlPart}/UsersPassword`);
  }

  public validateUser(userEmail: string): Observable<ObjectResult<boolean>> {
    const path = `validateUser/${userEmail}`;

    return this.getOneByPath(path);
  }

  public setPassword(model: SetPassword): Observable<SetPassword> {
    const path = 'setPassword';

    return super.post<SetPassword>(path, model);
  }

  public resetPassword(model: ResetPassword): Observable<ResetPassword> {
    const path = 'resetPassword';

    return super.post<ResetPassword>(path, model);
  }

  public sendResetPasswordNotification(model: ResetPasswordNotification) {
    return super.post(`sendResetPasswordNotification`, model);
  }

  public sendLockUserNotification(emailAddress: string) {
    return super.post(`sendLockUserNotification`, emailAddress);
  }
}
