import { CertificationType } from './../../../../../../../shared-lib/src/lib/models/certification-type.model';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CertificationTypeService } from '../../../../../../../shared-lib/src/lib/services/certification-type.service';

@Injectable({
  providedIn: 'root',
})
export class CertificationTypeResolver implements Resolve<CertificationType[]> {
  constructor(private certificationTypeService: CertificationTypeService) {}

  public resolve(): Observable<CertificationType[]> {
    return this.certificationTypeService.getAllWithCertificates(true);
  }
}
