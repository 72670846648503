import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionDirective } from './directives/permission.directive';

@NgModule({
  declarations: [],
  imports: [PermissionDirective, CommonModule],
  exports: [PermissionDirective],
})
export class SharedLibModule {}
