import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { Login } from './models/login.model';
import { AuthService } from '../../services/auth.service';
import { ApiToken } from '../../models/login-response.model';
import { filter, firstValueFrom, takeUntil, tap } from 'rxjs';
import { BaseComponent } from '../base-component/base-component.component';
import { AuthConstants } from '../../utils/constants/auth-constants';
import { LogoComponent } from '../logo/logo.component';
import { HttpStatusCode } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { UserPasswordService } from '../../services/user-password.service';
import { LoginPathConstants } from '../../utils/constants/component-path-constants';
import { ErrorConstants } from '../../utils/constants/error-constants';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    LogoComponent,
    RouterModule,
    MatIconModule,
    FlexLayoutModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent extends BaseComponent {
  @ViewChild('form') public form!: NgForm;
  public model: Login = {};
  public loggedUserId!: string;
  public errorMessage: string | null = null;
  public hidePassword = true;
  public loginPathConstants = LoginPathConstants;
  public errorConstants = ErrorConstants;

  constructor(
    private authService: AuthService,
    private userPasswordService: UserPasswordService,
    private router: Router,
  ) {
    super();
  }

  public login() {
    if (this.form.valid) {
      this.authService
        .login(this.model)
        .pipe(
          tap((response: ApiToken) => {
            if (response && response.token) {
              localStorage.setItem(AuthConstants.JWT_TOKEN, response.token);
              return true;
            }
            return false;
          }),
          takeUntil(this.destroy$),
          filter((r) => !!r),
        )
        .subscribe({
          next: () => {
            this.router.navigateByUrl('dashboard');
          },
          error: async (error) => {
            if (error.status === HttpStatusCode.Unauthorized) {
              if (error.error.isLockedOut) {
                await firstValueFrom(
                  this.userPasswordService.sendLockUserNotification(
                    this.model.email!,
                  ),
                );
                this.errorMessage = ErrorConstants.ACCOUNT_LOCKED_MESSAGE;
              } else {
                this.errorMessage =
                  ErrorConstants.INVALID_USERNAME_PASSWORD_MESSAGE;
              }
            } else {
              this.errorMessage = ErrorConstants.UNKNOWN_ERROR_MESSAGE;
            }
          },
        });
    }
  }

  public removeErrorMessage() {
    this.errorMessage = null;
  }
}
