<div class="task-container">
  <div class="task-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h5 class="tasks-title">
      TASKS <span class="task-count">{{ tasks.length }}</span>
    </h5>
    <a href="/dashboard" class="all-tasks-link">All tasks</a>
  </div>
  <div class="task-list-container">
    <div>
      <div class="task-card" *ngFor="let task of tasks">
        <div class="task-left">
          <input type="checkbox" />
          <div class="task-info">
            <h4>{{ task.title }}</h4>
            <p class="task-description">{{ task.description }}</p>
          </div>
        </div>
        <div class="task-right">
          <span
            class="task-status"
            *ngIf="task.isCritical"
            [class.critical]="task.isCritical"
            >{{ task.status }}</span
          >
          <span class="task-date" *ngIf="!task.isCritical">{{
            task.dueDate | date: "dd-MM-yyyy"
          }}</span>
          <mat-icon class="more-icon" svgIcon="more_vert"></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
