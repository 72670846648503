<div class="login-page">
  <div class="main-content">
    <div class="secondary-logo"></div>
    <div class="main-text">
      <p>BOOK, <br />MANAGE, <br />FLY</p>
    </div>
  </div>
  <div class="card-content">
    <div class="main-logo"></div>
    <div class="login-container">
      <mat-card class="login-card">
        <mat-card-content>
          @if (
            errorMessage &&
            errorMessage !== errorConstants.INVALID_USERNAME_PASSWORD_MESSAGE
          ) {
            <div class="error-message">
              {{ errorMessage }}
            </div>
          }
          <h1>LOGIN</h1>
          <form class="login-form" #form="ngForm">
            <label for="email" class="field-label">Email</label>
            <mat-form-field appearance="outline">
              <mat-icon matPrefix svgIcon="email_icon"></mat-icon>
              <input
                type="email"
                matInput
                [(ngModel)]="model.email"
                name="email"
                #email="ngModel"
                (input)="removeErrorMessage()"
                required
                placeholder="Enter your email"
              />
              <button mat-icon-button matSuffix aria-label="Email icon">
                <mat-icon iconPositionEnd svgIcon="help_icon"></mat-icon>
              </button>
              @if (
                errorMessage ===
                errorConstants.INVALID_USERNAME_PASSWORD_MESSAGE
              ) {
                <mat-hint class="error-validation">
                  <mat-icon svgIcon="error_icon"></mat-icon>
                  Email address is invalid</mat-hint
                >
              }
              @if (email.invalid) {
                <mat-error class="error-validation">
                  <mat-icon svgIcon="error_icon"></mat-icon>
                  Email address is required</mat-error
                >
              }
            </mat-form-field>

            <label for="password" class="field-label">Password</label>
            <mat-form-field appearance="outline">
              <mat-icon matPrefix svgIcon="lock_icon"></mat-icon>
              <input
                matInput
                [(ngModel)]="model.password"
                [type]="hidePassword ? 'password' : 'text'"
                name="password"
                #password="ngModel"
                placeholder="*********"
                (input)="removeErrorMessage()"
                required
                placeholder="Enter your password"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="hidePassword = !hidePassword"
              >
                <mat-icon
                  [svgIcon]="
                    !hidePassword ? 'visibility_off_icon' : 'visibility_icon'
                  "
                ></mat-icon>
              </button>
              @if (
                errorMessage ===
                errorConstants.INVALID_USERNAME_PASSWORD_MESSAGE
              ) {
                <mat-hint class="error-validation">
                  <mat-icon svgIcon="error_icon"></mat-icon>
                  Password is invalid</mat-hint
                >
              }
              @if (password.invalid) {
                <mat-error class="error-validation">
                  <mat-icon svgIcon="error_icon"></mat-icon>
                  Password is required</mat-error
                >
              }
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                <a
                  class="reset-link"
                  [routerLink]="[loginPathConstants.FORGOT_PASSWORD]"
                >
                  Reset password
                </a>
              </div>
              <button (click)="login()" mat-flat-button>
                Login
                <mat-icon
                  iconPositionEnd
                  svgIcon="arrow_forward_icon"
                ></mat-icon>
              </button>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
