import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Directive({
  selector: '[appPasswordPolicy]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordPolicyDirective,
      multi: true,
    },
  ],
})
export class PasswordPolicyDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const password = control.value;

    const errors: ValidationErrors = {};

    if (!password) {
      return null;
    }

    if (password.length < 8) {
      errors['minLength'] = 'Password must be at least 8 characters long.';
    }

    if (!/[A-Z]/.test(password)) {
      errors['uppercase'] =
        'Password must contain at least one uppercase letter.';
    }

    if (!/\d/.test(password)) {
      errors['number'] = 'Password must contain at least one number.';
    }

    if (!/[!-/:-@[-`{-~]/.test(password)) {
      errors['specialChar'] =
        'Password must contain at least one special character.';
    }

    return Object.keys(errors).length > 0 ? errors : null;
  }
}
