import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-dashboard-maintenance',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './dashboard-maintenance.component.html',
  styleUrl: './dashboard-maintenance.component.scss',
})
export class DashboardMaintenanceComponent {
  public maintenances = [
    {
      title: 'Maintenance task',
      description: 'Description',
      isCritical: true,
      status: 'CRITICAL',
    },
    {
      title: 'Maintenance task',
      description: 'Description',
      dueDate: new Date('2024-09-16'),
    },
    {
      title: 'Maintenance task',
      description: 'Description',
      dueDate: new Date('2024-09-21'),
    },
    {
      title: 'Maintenance task',
      description: 'Description',
      dueDate: new Date('2024-09-21'),
    },
    {
      title: 'Maintenance task',
      description: 'Description',
      dueDate: new Date('2024-09-21'),
    },
  ];
}
