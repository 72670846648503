import { Component, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FlexModule } from 'ngx-flexible-layout';
import { Router, RouterModule } from '@angular/router';
import { ResetPasswordNotification } from './models/reset-password-notification.model';
import { firstValueFrom } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { LogoComponent } from '../logo/logo.component';
import { UserPasswordService } from '../../services/user-password.service';
import { LoginPathConstants } from '../../utils/constants/component-path-constants';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    LogoComponent,
    FlexModule,
    MatIconModule,
    RouterModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  @ViewChild('form') public form!: NgForm;
  public model: ResetPasswordNotification = {};
  public loginPathConstants = LoginPathConstants;
  public infoMessage!: string;
  public cardClass = 'reset-password-card';

  constructor(
    private router: Router,
    private userPasswordService: UserPasswordService,
  ) {}

  public async reset(): Promise<void> {
    if (this.form.valid) {
      await firstValueFrom(
        this.userPasswordService.sendResetPasswordNotification(this.model),
      );
      this.infoMessage = 'The reset email has been sent to your email address.';
      this.model = {};
    }
  }

  public clearInfoMessage() {
    this.infoMessage = '';
  }

  public goToLogin() {
    this.router.navigateByUrl('login');
  }
}
