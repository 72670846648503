<div class="calendar-container">
  <div
    class="calendar-header"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div
      class="month-year"
      fxLayout="row"
      fxLayoutGap="1rem"
      fxLayoutAlign="start center"
    >
      <mat-icon svgIcon="dashboard_calendar_icon"></mat-icon>
      <h5 class="calendar-title">JULY 2024</h5>
    </div>
    <div class="navigation" fxLayout="row" fxLayoutAlign="end center">
      <mat-icon svgIcon="chevron_left_icon"></mat-icon>
      <mat-icon svgIcon="chevron_right_icon"></mat-icon>
    </div>
  </div>

  <div
    class="calendar-days"
    fxLayout="row"
    [fxLayoutGap]="!tabletLandscape ? '2.5rem' : '1.4rem'"
    fxLayoutAlign="space-between center"
  >
    <span>Mon</span>
    <span>Tue</span>
    <span>Wed</span>
    <span>Thu</span>
    <span>Fri</span>
    <span>Sat</span>
    <span>Sun</span>
  </div>

  <div
    class="calendar-dates"
    fxLayout="row"
    [fxLayoutGap]="!tabletLandscape ? '2.5rem' : '1.4rem'"
    fxLayoutAlign="space-between center"
  >
    <span>22</span>
    <span>23</span>
    <span>24</span>
    <span class="selected">25</span>
    <span>26</span>
    <span>27</span>
    <span>28</span>
  </div>

  <div class="events">
    <div class="event">
      <div class="event-time">All day</div>
      <mat-divider class="calendar-divider" vertical></mat-divider>
      <div class="event-title">Event title</div>
    </div>
    <div class="event">
      <div class="event-time">16:30 - 18:00</div>
      <mat-divider class="calendar-divider" vertical></mat-divider>
      <div class="event-title">Event title</div>
    </div>
  </div>
</div>
