import { Resolve } from '@angular/router';
import { Role } from '../../../../../../../shared-lib/src/lib/models/role.model';
import { RoleService } from '../../../../../../../shared-lib/src/lib/services/role.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RolesResolver implements Resolve<Role[]> {
  constructor(private roleService: RoleService) {}

  public resolve(): Observable<Role[]> {
    return this.roleService.getAll<Role>();
  }
}
