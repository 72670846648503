import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  selector: 'app-dashboard-missions',
  standalone: true,
  imports: [
    MatCardModule,
    FlexLayoutModule,
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './dashboard-missions.component.html',
  styleUrls: ['./dashboard-missions.component.scss'],
})
export class DashboardMissionsComponent {
  public missions = [{}, {}, {}];

  public visibleMissions: any[] = [];
  public startIndex = 0;
  public itemsPerPage = 3;

  constructor() {
    this.updateVisibleMissions();
  }

  public updateVisibleMissions() {
    this.visibleMissions = this.missions.slice(
      this.startIndex,
      this.startIndex + this.itemsPerPage,
    );
  }

  public nextPage() {}

  public previousPage() {}
}
