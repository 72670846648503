<div>
  <mat-drawer-container class="drawer-container">
    <!-- Drawer conditionally rendered based on screen size -->
    <mat-drawer
      #drawer
      [mode]="tabletPortrait ? 'over' : 'side'"
      [position]="tabletPortrait ? 'end' : 'start'"
      [opened]="!tabletPortrait"
      class="side-drawer"
    >
      <!-- Small screen drawer template -->
      <ng-container *ngIf="tabletPortrait; else largeScreenDrawer">
        <ng-container *ngTemplateOutlet="smallScreenDrawer"></ng-container>
      </ng-container>

      <!-- Large screen drawer template -->
      <ng-template #largeScreenDrawer>
        <ng-container
          *ngTemplateOutlet="largeScreenDrawerTemplate"
        ></ng-container>
      </ng-template>
    </mat-drawer>

    <!-- Main content -->
    <mat-drawer-content>
      <mat-toolbar *ngIf="tabletPortrait" color="primary" class="top-toolbar">
        <mat-icon
          svgIcon="dashboard_logo"
          class="tablet-portrait-logo"
        ></mat-icon>
        <span fxFlex></span>
        <div fxLayout="row" fxLayoutAlign="center center">
          <div [permission]="permissionConstants.AdministratorPermissions">
            <button mat-button [matMenuTriggerFor]="adminMenu">
              <span fxLayout="row" fxLayoutAlign="center center">
                <mat-icon
                  svgIcon="supervised_user_circle_icon"
                  class="top-menu-icon"
                ></mat-icon>
              </span>
            </button>
            <mat-menu #adminMenu="matMenu">
              <button
                class="administration-menu"
                mat-menu-item
                [routerLink]="[userPathConstants.USERS_OVERVIEW]"
              >
                <mat-icon svgIcon="supervisor_account_icon"></mat-icon>
                Manage users
              </button>
              <button
                class="administration-menu"
                mat-menu-item
                [routerLink]="[rolePathConstants.ROLES_OVERVIEW]"
              >
                <mat-icon svgIcon="security_icon"></mat-icon>
                Manage roles
              </button>
            </mat-menu>
          </div>
          <button mat-button>
            <span fxLayout="row" fxLayoutAlign="center center">
              <mat-icon
                svgIcon="notification_icon"
                class="top-menu-icon"
              ></mat-icon>
            </span>
          </button>
          <button mat-button>
            <span fxLayout="row" fxLayoutAlign="center center">
              <mat-icon
                svgIcon="account_circle"
                class="top-menu-icon"
              ></mat-icon>
            </span>
          </button>
          <div class="vertical-divider">
            <mat-divider vertical="true"></mat-divider>
          </div>
          <button mat-icon-button (click)="toggleDrawer()">
            <mat-icon class="top-menu-icon" svgIcon="menu_icon"></mat-icon>
          </button>
        </div>
      </mat-toolbar>
      <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field class="search-field" appearance="outline">
          <input
            matInput
            (input)="onSearchTermChange($event)"
            [value]="searchTerm"
            placeholder="What are you looking for?"
          />
          <mat-icon svgIcon="search_icon" matSuffix></mat-icon>
        </mat-form-field>
        <div
          *ngIf="!tabletPortrait"
          class="header-buttons"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <div [permission]="permissionConstants.AdministratorPermissions">
            <button mat-button [matMenuTriggerFor]="adminMenu">
              <span
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="0.8rem"
              >
                <mat-icon
                  svgIcon="supervised_user_circle_icon"
                  class="top-menu-icon"
                ></mat-icon>
              </span>
            </button>
            <mat-menu #adminMenu="matMenu">
              <button
                class="administration-menu"
                mat-menu-item
                [routerLink]="[userPathConstants.USERS_OVERVIEW]"
              >
                <mat-icon svgIcon="supervisor_account_icon"></mat-icon>
                Manage users
              </button>
              <button
                class="administration-menu"
                mat-menu-item
                [routerLink]="[rolePathConstants.ROLES_OVERVIEW]"
              >
                <mat-icon svgIcon="security_icon"></mat-icon>
                Manage roles
              </button>
            </mat-menu>
          </div>

          <button mat-button>
            <span
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="0.8rem"
            >
              <mat-icon
                svgIcon="notification_icon"
                class="top-menu-icon"
              ></mat-icon>
            </span>
          </button>

          <button mat-button>
            <span
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="0.8rem"
            >
              <mat-icon
                svgIcon="account_circle"
                class="top-menu-icon"
              ></mat-icon>
            </span>
          </button>
        </div>
      </div>

      <div
        class="region-content"
        *ngIf="isDashboardRoute()"
        [fxLayout]="tabletPortrait ? 'column' : 'row'"
        fxLayoutGap="2rem"
      >
        <div
          class="first-region"
          [fxFlex]="tabletLandscape ? '70%' : '60%'"
          fxLayout="column"
          fxLayoutGap="2rem"
        >
          <div>
            <h4>Hello {{ currentUser.fullName }}</h4>
            <p>Have a great day. All missions and tasks you’ll find here.</p>
            <app-dashboard-missions></app-dashboard-missions>
          </div>

          <div fxLayout="row" fxLayoutGap="2rem">
            <div fxFlex="50%">
              <app-dashboard-tasks></app-dashboard-tasks>
            </div>
            <div fxFlex="50%">
              <app-dashboard-maintenance></app-dashboard-maintenance>
            </div>
          </div>
        </div>

        <ng-container *ngIf="tabletPortrait; else regularSecondRegion">
          <ng-container
            *ngTemplateOutlet="tabletPortraitSecondRegion"
          ></ng-container>
        </ng-container>
      </div>
      <div [ngClass]="{ 'router-outlet-elements': !isDashboardRoute() }">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

<ng-template #tabletPortraitSecondRegion>
  <div fxLayoutGap="2rem" class="sidebar second-region">
    <div class="second-region-wrapper">
      <div fxLayout="column" fxFlex="50%" fxLayoutGap="2rem">
        <div>
          <app-dashboard-weather-forecast></app-dashboard-weather-forecast>
        </div>
        <div>
          <app-dashboard-team-members></app-dashboard-team-members>
        </div>
      </div>

      <div fxLayout="column" fxFlex="50%">
        <app-dashboard-calendar></app-dashboard-calendar>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #regularSecondRegion>
  <div
    [fxFlex]="tabletLandscape ? '30%' : '40%'"
    fxLayout="column"
    fxLayoutGap="1rem"
    class="sidebar second-region"
  >
    <div fxLayout="column" fxFlex="40%">
      <app-dashboard-weather-forecast></app-dashboard-weather-forecast>
    </div>
    <div fxLayout="column" fxFlex="40%">
      <app-dashboard-calendar></app-dashboard-calendar>
    </div>
    <div fxLayout="column" fxFlex="20%">
      <app-dashboard-team-members></app-dashboard-team-members>
    </div>
  </div>
</ng-template>

<!-- Template for small screen drawer (without Clients button) -->
<ng-template #smallScreenDrawer>
  <button class="close-button" mat-icon-button (click)="closeDrawer()">
    <mat-icon svgIcon="close_icon"></mat-icon>
  </button>
  <div class="drawer-content" fxLayout="column" fxLayoutGap="1rem">
    <button
      fxLayout="row"
      fxLayoutAlign="start center"
      mat-button
      [routerLink]="[dashboardPathConstants.DASHBOARD_OVERVIEW]"
      routerLinkActive="active"
      class="menu-button"
      (click)="closeDrawer()"
    >
      <mat-icon svgIcon="home_icon" class="icon-menu"></mat-icon>
      <h3 class="menu-text">DASHBOARD</h3>
    </button>
    <mat-divider class="custom-divider"></mat-divider>

    <button
      fxLayout="row"
      fxLayoutAlign="start center"
      mat-button
      [routerLink]="[dummyPathConstants.MISSIONS_PAGE]"
      routerLinkActive="active"
      class="menu-button"
      (click)="closeDrawer()"
    >
      <mat-icon svgIcon="missions_icon" class="icon-menu"></mat-icon>
      <h3 class="menu-text">MISSIONS</h3>
    </button>
    <mat-divider class="custom-divider"></mat-divider>

    <button
      fxLayout="row"
      fxLayoutAlign="start center"
      mat-button
      [routerLink]="[dummyPathConstants.FLEET_PAGE]"
      routerLinkActive="active"
      class="menu-button"
      (click)="closeDrawer()"
    >
      <mat-icon svgIcon="fleet_icon" class="icon-menu"></mat-icon>
      <h3 class="menu-text">FLEET</h3>
    </button>
    <mat-divider class="custom-divider"></mat-divider>

    <button
      fxLayout="row"
      fxLayoutAlign="start center"
      mat-button
      [routerLink]="[dummyPathConstants.PILOTS_CREW_PAGE]"
      [permission]="permissionConstants.PilotCrewModulePermissions"
      routerLinkActive="active"
      class="menu-button"
      (click)="closeDrawer()"
    >
      <mat-icon svgIcon="pilot_crew_icon" class="icon-menu"></mat-icon>
      <h3 class="menu-text">CREW & PILOTS</h3>
    </button>
    <mat-divider class="custom-divider"></mat-divider>

    <button
      fxLayout="row"
      fxLayoutAlign="start center"
      mat-button
      [routerLink]="[dummyPathConstants.TASKS_PAGE]"
      routerLinkActive="active"
      class="menu-button"
      (click)="closeDrawer()"
    >
      <mat-icon svgIcon="tasks_icon" class="icon-menu"></mat-icon>
      <h3 class="menu-text">TASKS</h3>
    </button>
    <mat-divider class="custom-divider"></mat-divider>

    <div class="text-buttons" fxLayout="column" fxLayoutGap="0.1rem">
      <button
        fxLayout="row"
        fxLayoutAlign="start center"
        mat-button
        [routerLink]="[contactPathConstants.PERSONS_OVERVIEW]"
        [permission]="permissionConstants.AppViewContactsModulePermissions"
        routerLinkActive="active"
        class="menu-button"
        (click)="closeDrawer()"
      >
        <p class="menu-text-portrait-view">Contacts</p>
      </button>
      <button
        fxLayout="row"
        fxLayoutAlign="start center"
        mat-button
        [routerLink]="[dummyPathConstants.CLIENTS_PAGE]"
        routerLinkActive="active"
        class="menu-button"
        (click)="closeDrawer()"
      >
        <p class="menu-text-portrait-view">Clients</p>
      </button>
      <button
        fxLayout="row"
        fxLayoutAlign="start center"
        mat-button
        [routerLink]="[dummyPathConstants.TRAINING_PAGE]"
        routerLinkActive="active"
        class="menu-button"
        (click)="closeDrawer()"
      >
        <p class="menu-text-portrait-view">Training</p>
      </button>
      <button
        fxLayout="row"
        fxLayoutAlign="start center"
        mat-button
        [routerLink]="[dummyPathConstants.SETTINGS_PAGE]"
        routerLinkActive="active"
        class="menu-button"
        (click)="closeDrawer()"
      >
        <p class="menu-text-portrait-view">Settings</p>
      </button>
      <button
        fxLayout="row"
        fxLayoutAlign="start center"
        mat-button
        (click)="logout()"
        class="menu-button"
      >
        <mat-icon svgIcon="logout_icon" class="logout-button"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #largeScreenDrawerTemplate>
  <div
    class="drawer-content"
    fxLayout="column"
    [fxLayoutGap]="tabletLandscape ? '1rem' : '3rem'"
  >
    <div id="logo">
      <mat-icon svgIcon="dashboard_logo" class="logo"></mat-icon>
    </div>
    <mat-divider class="large-screen-divider"></mat-divider>

    <button
      fxLayout="row"
      fxLayoutAlign="center center"
      mat-icon-button
      [routerLink]="[dashboardPathConstants.DASHBOARD_OVERVIEW]"
      routerLinkActive="active"
    >
      <mat-icon svgIcon="home_icon" class="icon-menu"></mat-icon>
      <div class="right-border"></div>
      <!-- Home -->
    </button>
    <button
      fxLayout="row"
      fxLayoutAlign="center center"
      mat-icon-button
      [routerLink]="[dummyPathConstants.FLEET_PAGE]"
      routerLinkActive="active"
    >
      <mat-icon svgIcon="fleet_icon" class="icon-menu"></mat-icon>
      <div class="right-border"></div>
      <!-- Fleet -->
    </button>
    <button
      fxLayout="row"
      fxLayoutAlign="center center"
      mat-icon-button
      [routerLink]="[dummyPathConstants.MISSIONS_PAGE]"
      routerLinkActive="active"
    >
      <mat-icon svgIcon="missions_icon" class="icon-menu"></mat-icon>
      <div class="right-border"></div>
      <!-- Missions -->
    </button>
    <button
      fxLayout="row"
      fxLayoutAlign="center center"
      mat-icon-button
      [routerLink]="[dummyPathConstants.PILOTS_CREW_PAGE]"
      [permission]="permissionConstants.PilotCrewModulePermissions"
      routerLinkActive="active"
    >
      <mat-icon svgIcon="pilot_crew_icon" class="icon-menu"></mat-icon>
      <div class="right-border"></div>
      <!-- Pilots & Crew -->
    </button>
    <button
      fxLayout="row"
      fxLayoutAlign="center center"
      mat-icon-button
      [routerLink]="[dummyPathConstants.TASKS_PAGE]"
      routerLinkActive="active"
    >
      <mat-icon svgIcon="tasks_icon" class="icon-menu"></mat-icon>
      <div class="right-border"></div>
      <!-- Tasks -->
    </button>

    <mat-divider class="large-screen-divider"></mat-divider>
    <div>
      <button
        class="align-text-button"
        mat-button
        [routerLink]="[contactPathConstants.PERSONS_OVERVIEW]"
        [permission]="permissionConstants.AppViewContactsModulePermissions"
        routerLinkActive="active"
      >
        <span class="menu-text">Contacts</span>
      </button>

      <button
        class="align-text-button"
        mat-button
        [routerLink]="[dummyPathConstants.CLIENTS_PAGE]"
        routerLinkActive="active"
      >
        <span class="menu-text">Clients</span>
      </button>

      <button
        class="align-text-button"
        mat-button
        [routerLink]="[dummyPathConstants.TRAINING_PAGE]"
        routerLinkActive="active"
      >
        <span class="menu-text">Training</span>
      </button>

      <button
        class="align-text-button"
        mat-button
        [routerLink]="[dummyPathConstants.SETTINGS_PAGE]"
        routerLinkActive="active"
      >
        <span class="menu-text">Settings</span>
      </button>
    </div>

    <mat-divider class="large-screen-divider"></mat-divider>
    <button mat-icon-button (click)="logout()" class="logout-button">
      <mat-icon svgIcon="logout_icon" class="icon-menu"></mat-icon>
    </button>
  </div>
</ng-template>
