<div class="set-password-page">
  <div class="main-content">
    <div class="secondary-logo"></div>
    <div class="main-text">
      <p>BOOK, <br />MANAGE, <br />FLY</p>
    </div>
  </div>
  <div class="card-content">
    <div class="main-logo"></div>
    <div class="set-password-container">
      <mat-card class="set-password-card">
        <mat-card-content>
          <h1>SET UP YOUR PASSWORD</h1>
          <div
            class="multi-error-message"
            *ngIf="password.errors && (password.dirty || password.touched)"
          >
            <div
              *ngIf="
                password.errors['minLength'] ||
                password.errors['uppercase'] ||
                password.errors['number'] ||
                password.errors['specialChar']
              "
              class="password-policy password-errors"
            >
              Set a strong password!
            </div>
            <ul
              [ngClass]="
                password.errors['minLength'] ||
                password.errors['uppercase'] ||
                password.errors['number'] ||
                password.errors['specialChar']
                  ? 'password-errors'
                  : ''
              "
            >
              <li *ngIf="password.errors['minLength']">
                {{ password.errors["minLength"] }}
              </li>
              <li *ngIf="password.errors['uppercase']">
                {{ password.errors["uppercase"] }}
              </li>
              <li *ngIf="password.errors['number']">
                {{ password.errors["number"] }}
              </li>
              <li *ngIf="password.errors['specialChar']">
                {{ password.errors["specialChar"] }}
              </li>
            </ul>
          </div>

          <div
            *ngIf="
              confirmPassword.errors &&
              (confirmPassword.dirty || confirmPassword.touched)
            "
          >
            <div
              *ngIf="confirmPassword.errors['passwordMismatch']"
              class="error-message"
            >
              {{ confirmPassword.errors["passwordMismatch"] }}
            </div>
          </div>

          <form class="set-password-form" #form="ngForm">
            <label for="password" class="field-label">Password</label>
            <mat-form-field appearance="outline">
              <mat-icon matPrefix svgIcon="lock_icon"></mat-icon>
              <input
                matInput
                [(ngModel)]="model.password"
                [type]="hidePassword ? 'password' : 'text'"
                type="password"
                name="password"
                #password="ngModel"
                placeholder="Enter your password"
                required
                appPasswordPolicy
              />
              <button
                mat-icon-button
                matSuffix
                (click)="hidePassword = !hidePassword"
              >
                <mat-icon
                  [svgIcon]="
                    !hidePassword ? 'visibility_off_icon' : 'visibility_icon'
                  "
                ></mat-icon>
              </button>
            </mat-form-field>
            <label for="confirmPassword" class="field-label"
              >Confirm password</label
            >
            <mat-form-field appearance="outline">
              <mat-icon matPrefix svgIcon="lock_icon"></mat-icon>
              <input
                matInput
                [(ngModel)]="model.confirmPassword"
                [type]="hideConfirmPassword ? 'password' : 'text'"
                type="password"
                name="confirmPassword"
                #confirmPassword="ngModel"
                placeholder="Confirm your password"
                required
                appConfirmPassword="password"
              />
              <button
                mat-icon-button
                matSuffix
                (click)="hideConfirmPassword = !hideConfirmPassword"
              >
                <mat-icon
                  [svgIcon]="
                    !hideConfirmPassword
                      ? 'visibility_off_icon'
                      : 'visibility_icon'
                  "
                ></mat-icon>
              </button>
            </mat-form-field>
            <button
              class="set-password-button"
              (click)="setPassword()"
              mat-flat-button
            >
              Set password & log in
              <mat-icon iconPositionEnd svgIcon="arrow_forward_icon"></mat-icon>
            </button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
