import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  selector: 'app-dashboard-team-members',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule],
  templateUrl: './dashboard-team-members.component.html',
  styleUrl: './dashboard-team-members.component.scss',
})
export class DashboardTeamMembersComponent {
  public members = [
    { color: '#987fdb' },
    { color: '#a0c795' },
    { color: '#70630a' },
    { color: '#d6165b' },
    { color: '#f1a6d1' },
    { color: '#6d1911' },
    { color: '#a17dc8' },
    { color: '#987fdb' },
    { color: '#987fdb' },
  ];

  public maxVisibleMembers = 0;

  get visibleMembers() {
    return this.members.slice(0, this.maxVisibleMembers);
  }

  get remainingMembersCount() {
    return this.members.length - this.maxVisibleMembers;
  }

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.TabletPortrait, Breakpoints.TabletLandscape])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.TabletLandscape]) {
          this.maxVisibleMembers = 4;
        } else {
          this.maxVisibleMembers = 7;
        }
      });
  }
}
