import { Routes } from '@angular/router';
import { LoginComponent } from '../../../shared-lib/src/lib/components/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { AuthGuard } from '../../../shared-lib/src/lib/services/auth-guard.service';
import { PermissionGuard } from '../../../shared-lib/src/lib/services/permission-guard.service';
import { PermissionConstants } from '../../../shared-lib/src/lib/utils/constants/permission-constants';
import { RolesResolver } from './features/admin/users/services/role.resolver';
import { UserNotRegisteredGuard } from '../../../shared-lib/src/lib/services/user-not-registered-guard.service';
import { PilotCrewComponent } from './features/pilot-crew/pilot-crew.component';
import { CertificationTypeResolver } from './features/admin/users/services/certification-type.resolver';
import { ContactComponent } from './features/contact/contact.component';
import { PersonTypeResolver } from './features/admin/users/services/person-type.resolver';
import { SetPasswordComponent } from '../../../shared-lib/src/lib/components/set-password/set-password.component';
import { ForgotPasswordComponent } from '../../../shared-lib/src/lib/components/forgot-password/forgot-password.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'admin/manage-users',
        loadComponent: () =>
          import('./features/admin/users/users.component').then(
            (m) => m.UsersComponent,
          ),
        canActivate: [AuthGuard, PermissionGuard],
        data: { permissions: PermissionConstants.AdministratorPermissions },
      },
      {
        path: 'admin/manage-users/add',
        loadComponent: () =>
          import(
            './features/admin/users/components/add-user/add-user.component'
          ).then((m) => m.AddUserComponent),
        canActivate: [AuthGuard, PermissionGuard],
        data: { permissions: PermissionConstants.AdministratorPermissions },
        resolve: { roles: RolesResolver },
      },
      {
        path: 'admin/manage-users/edit/:id',
        loadComponent: () =>
          import(
            './features/admin/users/components/edit-user/edit-user.component'
          ).then((m) => m.EditUserComponent),
        canActivate: [AuthGuard, PermissionGuard],
        data: { permissions: PermissionConstants.AdministratorPermissions },
        resolve: { roles: RolesResolver },
      },
      {
        path: 'admin/manage-roles',
        loadComponent: () =>
          import('./features/admin/roles/roles.component').then(
            (m) => m.RolesComponent,
          ),
        canActivate: [AuthGuard, PermissionGuard],
        data: { permissions: PermissionConstants.AdministratorPermissions },
      },
      {
        path: 'admin/manage-roles/edit/:roleName',
        loadComponent: () =>
          import(
            './features/admin/roles/components/edit-role/edit-role.component'
          ).then((m) => m.EditRoleComponent),
        canActivate: [AuthGuard, PermissionGuard],
        data: { permissions: PermissionConstants.AdministratorPermissions },
      },
      {
        path: 'missions',
        loadComponent: () =>
          import(
            '../../../shared-lib/src/lib/components/under-construction/under-construction.component'
          ).then((m) => m.UnderConstructionComponent),
      },
      {
        path: 'fleet',
        loadComponent: () =>
          import(
            '../../../shared-lib/src/lib/components/under-construction/under-construction.component'
          ).then((m) => m.UnderConstructionComponent),
      },
      {
        path: 'tasks',
        loadComponent: () =>
          import(
            '../../../shared-lib/src/lib/components/under-construction/under-construction.component'
          ).then((m) => m.UnderConstructionComponent),
      },
      {
        path: 'clients',
        loadComponent: () =>
          import(
            '../../../shared-lib/src/lib/components/under-construction/under-construction.component'
          ).then((m) => m.UnderConstructionComponent),
      },
      {
        path: 'training',
        loadComponent: () =>
          import(
            '../../../shared-lib/src/lib/components/under-construction/under-construction.component'
          ).then((m) => m.UnderConstructionComponent),
      },
      {
        path: 'settings',
        loadComponent: () =>
          import(
            '../../../shared-lib/src/lib/components/under-construction/under-construction.component'
          ).then((m) => m.UnderConstructionComponent),
      },
      {
        path: 'pilot-crew',
        component: PilotCrewComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: PermissionConstants.AppAccessPilotCrewModulePermissions,
        },
        children: [
          {
            path: '',
            redirectTo: 'certifications',
            pathMatch: 'full',
          },
          {
            path: 'certifications',
            canActivate: [AuthGuard, PermissionGuard],
            data: {
              permissions:
                PermissionConstants.AppViewCertificationsModulePermissions,
            },
            loadComponent: () =>
              import(
                './features/pilot-crew/components/certifications/certifications.component'
              ).then((m) => m.CertificationsComponent),
          },
          {
            path: 'certifications/edit/:id',
            loadComponent: () =>
              import(
                './features/pilot-crew/components/certifications/edit-renew-certification/edit-renew-certification.component'
              ).then((m) => m.EditRenewCertificationComponent),
            canActivate: [AuthGuard, PermissionGuard],
            data: {
              permissions: PermissionConstants.AppEditCertificationPermissions,
            },
            resolve: { certificationTypes: CertificationTypeResolver },
          },
          {
            path: 'certifications/renew/:id',
            loadComponent: () =>
              import(
                './features/pilot-crew/components/certifications/edit-renew-certification/edit-renew-certification.component'
              ).then((m) => m.EditRenewCertificationComponent),
            canActivate: [AuthGuard, PermissionGuard],
            data: {
              permissions:
                PermissionConstants.AppRenewalCertificationPermissions,
            },
            resolve: { certificationTypes: CertificationTypeResolver },
          },
          {
            path: 'pilots',
            loadComponent: () =>
              import(
                '../../../shared-lib/src/lib/components/under-construction/under-construction.component'
              ).then((m) => m.UnderConstructionComponent),
          },
          {
            path: 'crew',
            loadComponent: () =>
              import(
                '../../../shared-lib/src/lib/components/under-construction/under-construction.component'
              ).then((m) => m.UnderConstructionComponent),
          },
        ],
      },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          permissions: PermissionConstants.AppViewContactsModulePermissions,
        },
        children: [
          {
            path: 'manage-persons',
            loadComponent: () =>
              import(
                './features/contact/components/person-overview/person-overview.component'
              ).then((m) => m.PersonOverviewComponent),
            canActivate: [AuthGuard, PermissionGuard],
            data: {
              permissions: PermissionConstants.AppViewPersonsPermissions,
            },
          },
          {
            path: 'manage-persons/add',
            loadComponent: () =>
              import(
                './features/contact/components/add-person/add-person.component'
              ).then((m) => m.AddPersonComponent),
            canActivate: [AuthGuard, PermissionGuard],
            data: {
              permissions: PermissionConstants.AppAddPersonsPermissions,
            },
            resolve: { personTypes: PersonTypeResolver },
          },
          {
            path: 'manage-persons/edit/:id',
            loadComponent: () =>
              import(
                './features/contact/components/edit-person/edit-person.component'
              ).then((m) => m.EditPersonComponent),
            canActivate: [AuthGuard, PermissionGuard],
            data: {
              permissions: PermissionConstants.AppEditPersonsPermissions,
            },
            resolve: { personTypes: PersonTypeResolver },
          },
        ],
      },
    ],
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    canActivate: [UserNotRegisteredGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
];
