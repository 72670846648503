<div class="team-members-container">
  <div class="team-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h5 class="team-members-title">ONLINE TEAM MEMBERS</h5>
    <a href="/dashboard" class="all-members-link">All members</a>
  </div>

  <div class="team-members-list">
    <div
      class="team-member-avatar"
      *ngFor="let member of visibleMembers"
      [ngStyle]="{ 'background-color': member.color }"
    ></div>
    <div
      class="remaining-avatar remaining-count"
      *ngIf="remainingMembersCount > 0"
    >
      <h6>+{{ remainingMembersCount }}</h6>
    </div>
  </div>
</div>
