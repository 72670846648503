import { Routes } from '@angular/router';
import { LoginComponent } from '../../../shared-lib/src/lib/components/login/login.component';
import { AuthGuard } from '../../../shared-lib/src/lib/services/auth-guard.service';
import { DashboardComponent } from '../../../fms-web/src/app/features/dashboard/dashboard.component';
import { UserNotRegisteredGuard } from '../../../shared-lib/src/lib/services/user-not-registered-guard.service';
import { SetPasswordComponent } from '../../../shared-lib/src/lib/components/set-password/set-password.component';
import { ForgotPasswordComponent } from '../../../shared-lib/src/lib/components/forgot-password/forgot-password.component';

export const routes: Routes = [ {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    canActivate: [UserNotRegisteredGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
];
