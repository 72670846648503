import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  selector: 'app-dashboard-tasks',
  standalone: true,
  imports: [CommonModule, MatIconModule, FlexLayoutModule],
  templateUrl: './dashboard-tasks.component.html',
  styleUrl: './dashboard-tasks.component.scss',
})
export class DashboardTasksComponent {
  public tasks = [
    {
      title: 'To do',
      description: 'Description',
      isCritical: true,
      status: 'CRITICAL',
    },
    {
      title: 'To do',
      description: 'Description',
      dueDate: new Date('2024-09-16'),
    },
    {
      title: 'To do',
      description: 'Description',
      dueDate: new Date('2024-09-21'),
    },
    {
      title: 'To do',
      description: 'Description',
      dueDate: new Date('2024-09-21'),
    },
    {
      title: 'To do',
      description: 'Description',
      dueDate: new Date('2024-09-21'),
    },
  ];
}
