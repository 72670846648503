import { ApplicationConfig, provideZoneChangeDetection, isDevMode, importProvidersFrom, APP_INITIALIZER } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { appHttpInterceptor } from '../../../shared-lib/src/lib/services/http.interceptor';
import { ToastrConfig } from '../../../shared-lib/src/lib/services/toastr.config';
import { IconService } from '../../../shared-lib/src/lib/services/icon.service';
import { initializeIcons } from '../../../fms-web/src/app/app.config';

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes), provideServiceWorker('ngsw-worker.js', {
    enabled: !isDevMode(),
    registrationStrategy: 'registerWhenStable:30000'
  }), provideAnimationsAsync(),
  provideHttpClient(withInterceptors([appHttpInterceptor])),
  importProvidersFrom(ToastrConfig),
  IconService,
  {
    provide: APP_INITIALIZER,
    useFactory: initializeIcons,
    deps: [IconService],
    multi: true
  },]
};
