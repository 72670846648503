import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { PilotCrewPathConstants } from '../../../../../shared-lib/src/lib/utils/constants/component-path-constants';
import { BaseComponent } from '../../../../../shared-lib/src/lib/components/base-component/base-component.component';
import { PilotCrewAccessService } from '../../../../../shared-lib/src/lib/services/pilot-crew-access.service';

@Component({
  selector: 'app-pilot-crew',
  standalone: true,
  imports: [CommonModule, MatTabsModule, RouterModule],
  templateUrl: './pilot-crew.component.html',
  styleUrl: './pilot-crew.component.scss',
})
export class PilotCrewComponent extends BaseComponent implements OnInit {
  public selectedTabIndex = 0;
  public pilotCrewPathConstants = PilotCrewPathConstants;

  public availableTabs: Array<{ label: string; index: number }> = [];

  public get canViewCertifications(): boolean {
    return this.accessService.canViewCertifications();
  }

  public get canViewPilots(): boolean {
    return true;
  }

  public get canViewCrew(): boolean {
    return true;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accessService: PilotCrewAccessService
  ) {
    super();
  }

  public ngOnInit() {
    this.displayCurrentTab();
  }

  private displayCurrentTab() {
    this.availableTabs = this.getAvailableTabs();
    const currentRoute = this.route.firstChild?.snapshot.url[0]?.path;

    switch (currentRoute) {
      case this.pilotCrewPathConstants.CERTIFICATIONS:
        this.setSelectedTabByLabel(this.pilotCrewPathConstants.CERTIFICATIONS);
        break;
      case this.pilotCrewPathConstants.PILOTS:
        this.setSelectedTabByLabel(this.pilotCrewPathConstants.PILOTS);
        break;
      case this.pilotCrewPathConstants.CREW:
        this.setSelectedTabByLabel(this.pilotCrewPathConstants.CREW);
        break;
      default:
        this.selectedTabIndex = 0; // Default to first tab
        break;
    }
  }


  public onTabChange(event: MatTabChangeEvent) {
    const selectedTab = this.availableTabs[event.index];

    switch (selectedTab.label) {
      case this.pilotCrewPathConstants.CERTIFICATIONS:
        this.router.navigate([this.pilotCrewPathConstants.CERTIFICATIONS], {
          relativeTo: this.route,
        });
        break;
      case this.pilotCrewPathConstants.PILOTS:
        this.router.navigate([this.pilotCrewPathConstants.PILOTS], {
          relativeTo: this.route,
        });
        break;
      case this.pilotCrewPathConstants.CREW:
        this.router.navigate([this.pilotCrewPathConstants.CREW], {
          relativeTo: this.route,
        });
        break;
    }
  }

  private getAvailableTabs() {
    const tabs = [];
    let index = 0;
    if (this.canViewCertifications) {
      tabs.push({ label: this.pilotCrewPathConstants.CERTIFICATIONS, index: index });
      index++;
    }
    if (this.canViewPilots) {
      tabs.push({ label: this.pilotCrewPathConstants.PILOTS, index: index });
      index++;
    }
    if (this.canViewCrew) {
      tabs.push({ label: this.pilotCrewPathConstants.CREW, index: index });
      index++;
    }
    return tabs;
  }

  private setSelectedTabByLabel(label: string) {
    const tab = this.availableTabs.find((t) => t.label === label);
    if (tab && this.hasPermission(tab.label)) {
      this.selectedTabIndex = this.availableTabs.indexOf(tab);
      this.router.navigate([tab.label], {
        relativeTo: this.route,
      });
    } else {
      this.selectedTabIndex = this.availableTabs[0].index;
      this.router.navigate([this.availableTabs[0].label], {
        relativeTo: this.route,
      });
    }
  }

  private hasPermission(tabName: string): boolean {
    switch (tabName) {
      case this.pilotCrewPathConstants.CERTIFICATIONS:
        return this.canViewCertifications;
      case this.pilotCrewPathConstants.PILOTS:
        return this.canViewPilots;
      case this.pilotCrewPathConstants.CREW:
        return this.canViewCrew;
      default:
        return this.canViewCertifications;
    }
  }
}
