import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { environment } from '../../../../fms-web/src/environments/environment';
import { CertificationType } from '../models/certification-type.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CertificationTypeService extends DataService {
  constructor(public override http: HttpClient) {
    super(http, `${environment.certificationsApiUrlPart}/CertificationTypes`);
  }

  public getAllWithCertificates(
    includeCertificates = false,
  ): Observable<CertificationType[]> {
    const path = `${includeCertificates}`;

    return super.getMany<CertificationType>(path);
  }
}
