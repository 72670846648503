import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from 'ngx-flexible-layout';

@Component({
  selector: 'app-dashboard-calendar',
  standalone: true,
  imports: [MatIconModule, MatDividerModule, FlexLayoutModule],
  templateUrl: './dashboard-calendar.component.html',
  styleUrl: './dashboard-calendar.component.scss',
})
export class DashboardCalendarComponent {
  public tabletLandscape = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.TabletPortrait, Breakpoints.TabletLandscape])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.TabletLandscape]) {
          this.tabletLandscape = true;
        } else {
          this.tabletLandscape = false;
        }
      });
  }
}
