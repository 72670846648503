import { HttpInterceptorFn } from '@angular/common/http';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { catchError, filter, tap, throwError } from 'rxjs';
import { AuthConstants } from '../utils/constants/auth-constants';
import { AlertService } from './alert.service';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';

export const appHttpInterceptor: HttpInterceptorFn = (req, next) => {
  //const alertService = AlertService;
  //const authService = AuthService;
  const token = localStorage.getItem(AuthConstants.JWT_TOKEN);

  let headers = req.headers;

  if (token) {
    headers = headers.set('Authorization', `Bearer ${token}`);
  }

  if (!req.url.includes('upload')) {
    headers = headers.set('Content-Type', 'application/json');
  }

  const request = req.clone({ headers });

  return next(request).pipe(
    filter((e) => e.type !== 0),
    tap(() => {}),
    catchError((err: HttpErrorResponse) => {
      if (err.status === HttpStatusCode.Forbidden) {
        //alertService.showError(err.error.message);
        return throwError(() => err);
      }

      if (err.error?.isLockedOut) {
        //authService.logout();
        return throwError(() => err);
      }

      if (err.status === HttpStatusCode.UnprocessableEntity) {
        //alertService.showError(err.error.detail);
        return throwError(() => err);
      }

      if (err.status !== HttpStatusCode.Unauthorized) {
        if (err.error && err.error.errors) {
          Object.values(err.error.errors)
            .flat()
            .forEach((message) => {
              //alertService.showError(message as string);
            });
        } else {
          //alertService.showError(err.statusText);
        }
      }
      return throwError(() => err);
    }),
  );
};
