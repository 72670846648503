import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectResult } from '../models/object-result.model';
import { LoginPathConstants } from '../utils/constants/component-path-constants';
import { UserPasswordService } from './user-password.service';

@Injectable({
  providedIn: 'root',
})
export class UserNotRegisteredGuard implements CanActivate {
  constructor(
    private userPasswordService: UserPasswordService,
    private router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> {
    const email = route.queryParams['email'];
    const isResetPassword = route.queryParams['reset'];

    if (isResetPassword) {
      return of(true);
    }

    return this.userPasswordService.validateUser(email || '').pipe(
      map((res: ObjectResult<boolean>) => {
        if (res.value) {
          return this.router.createUrlTree([`${LoginPathConstants.LOGIN}`]);
        } else {
          return true;
        }
      }),
    );
  }
}
