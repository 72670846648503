<div class="weather-container">
  <div fxLayout="row" fxLayoutGap="1rem" class="weather-header">
    <mat-icon svgIcon="sunny_icon"></mat-icon>
    <h5 class="weather-title">TODAY'S WEATHER FORECAST</h5>
  </div>

  <div fxLayout="column" class="weather-card">
    <div fxFlex="50%" class="message"><h5>UNDER CONSTRUCTION</h5></div>
    <div fxFlex="50%" class="weather-data-table">
      <div class="data-item border-item">
        <strong>Wind</strong>
      </div>
      <div class="data-item">
        <strong>Humidity</strong>
      </div>
      <div class="data-item border-item">
        <strong>Visibility</strong>
      </div>
      <div class="data-item">
        <strong>UV</strong>
      </div>
    </div>
  </div>
</div>
