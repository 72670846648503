import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LogoComponent } from '../../../../../shared-lib/src/lib/components/logo/logo.component';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { AuthService } from '../../../../../shared-lib/src/lib/services/auth.service';
import { User } from '../../../../../shared-lib/src/lib/models/user.model';
import { CommonModule } from '@angular/common';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { AccountService } from '../../../../../shared-lib/src/lib/services/account.service';
import { PermissionDirective } from '../../../../../shared-lib/src/lib/directives/permission.directive';
import { PermissionConstants } from '../../../../../shared-lib/src/lib/utils/constants/permission-constants';
import {
  DashboardPathConstants,
  DummyPathConstants,
  ContactPathConstants,
  RolePathConstants,
  UserPathConstants,
} from '../../../../../shared-lib/src/lib/utils/constants/component-path-constants';
import { MatCardModule } from '@angular/material/card';
import { SearchService } from '../../../../../shared-lib/src/lib/services/search.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DashboardMissionsComponent } from './components/dashboard-missions/dashboard-missions.component';
import { DashboardTasksComponent } from './components/dashboard-tasks/dashboard-tasks.component';
import { DashboardMaintenanceComponent } from './components/dashboard-maintenance/dashboard-maintenance.component';
import { DashboardTeamMembersComponent } from './components/dashboard-team-members/dashboard-team-members.component';
import { DashboardWeatherForecastComponent } from './components/dashboard-weather-forecast/dashboard-weather-forecast.component';
import { DashboardCalendarComponent } from './components/dashboard-calendar/dashboard-calendar.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    LogoComponent,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatMenuModule,
    FlexLayoutModule,
    RouterModule,
    PermissionDirective,
    MatCardModule,
    MatToolbarModule,
    DashboardMissionsComponent,
    DashboardTasksComponent,
    DashboardMaintenanceComponent,
    DashboardTeamMembersComponent,
    DashboardWeatherForecastComponent,
    DashboardCalendarComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatDrawer;
  public searchTerm!: string;
  public currentUser: User = {};
  public currentDate = new Date();
  public permissionConstants = PermissionConstants;
  public dashboardPathConstants = DashboardPathConstants;
  public userPathConstants = UserPathConstants;
  public rolePathConstants = RolePathConstants;
  public dummyPathConstants = DummyPathConstants;
  public contactPathConstants = ContactPathConstants;
  public tabletPortrait = false;
  public tabletLandscape = false;

  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private searchService: SearchService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  public ngOnInit(): void {
    this.accountService.getCurrentUser().subscribe((user) => {
      this.currentUser = user;
    });

    this.searchService.searchTerm$.subscribe((term) => {
      this.searchTerm = term;
    });

    this.breakpointObserver
      .observe([Breakpoints.TabletPortrait, Breakpoints.TabletLandscape])
      .subscribe((result) => {
        if (result.breakpoints[Breakpoints.TabletPortrait]) {
          this.tabletPortrait = true;
          this.tabletLandscape = false;
        } else if (result.breakpoints[Breakpoints.TabletLandscape]) {
          this.tabletPortrait = false;
          this.tabletLandscape = true;
        } else {
          this.tabletLandscape = false;
          this.tabletPortrait = false;
        }
      });
  }

  toggleDrawer(): void {
    this.drawer.toggle();
  }

  closeDrawer(): void {
    this.drawer.close();
  }

  public isDashboardRoute(): boolean {
    return this.router.url === '/dashboard';
  }

  public logout(): void {
    this.authService.logout();
  }

  public onSearchTermChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchTerm = inputElement.value;
    this.searchService.setSearchTerm(this.searchTerm);
  }
}
