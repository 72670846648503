import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { PersonType } from '../../../../../../../shared-lib/src/lib/models/person-type.model';
import { PersonTypeService } from '../../../../../../../shared-lib/src/lib/services/person-type.service';

@Injectable({
  providedIn: 'root',
})
export class PersonTypeResolver implements Resolve<PersonType[]> {
  constructor(private personTypeService: PersonTypeService) {}

  public resolve(): Observable<PersonType[]> {
    return this.personTypeService.getAll<PersonType>();
  }
}
