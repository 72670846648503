<div class="maintenance-container">
  <div class="maintenance-header">
    <h5>MAINTENANCE</h5>
    <a href="/dashboard" class="all-maintenance-link">All maintenance</a>
  </div>
  <div class="maintenance-list-container">
    <div>
      <div class="maintenance-card" *ngFor="let maintenance of maintenances">
        <div class="maintenance-left">
          <input type="checkbox" />
          <div class="maintenance-info">
            <h4>{{ maintenance.title }}</h4>
            <p class="maintenance-description">{{ maintenance.description }}</p>
          </div>
        </div>
        <div class="maintenance-right">
          <span
            class="maintenance-status"
            *ngIf="maintenance.isCritical"
            [class.critical]="maintenance.isCritical"
            >{{ maintenance.status }}</span
          >
          <span class="maintenance-date" *ngIf="!maintenance.isCritical">{{
            maintenance.dueDate | date: "dd-MM-yyyy"
          }}</span>
          <mat-icon class="more-icon" svgIcon="more_vert"></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
