import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { environment } from '../../../../fms-web/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PersonTypeService extends DataService {
  constructor(public override http: HttpClient) {
    super(http, `${environment.employeesApiUrlPart}/PersonTypes`);
  }
}
