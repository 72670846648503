import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private iconNames = [
    'email_icon',
    'lock_icon',
    'visibility_icon',
    'visibility_off_icon',
    'arrow_forward_icon',
    'help_icon',
    'error_icon',
    'home_icon',
    'fleet_icon',
    'missions_icon',
    'pilot_crew_icon',
    'tasks_icon',
    'logout_icon',
    'dashboard_logo',
    'notification_icon',
    'close_icon',
    'menu_icon',
    'time_icon',
    'chevron_left_icon',
    'chevron_right_icon',
    'account_circle',
    'supervised_user_circle_icon',
    'sunny_icon',
    'dashboard_calendar_icon',
    'dashboard_today_icon',
    'plus_icon',
    'more_vert',
    'security_icon',
    'supervisor_account_icon',
    'search_icon',
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  public registerIcons(): void {
    this.iconNames.forEach((iconName) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `icons/${iconName}.svg`,
        ),
      );
    });
  }
}
