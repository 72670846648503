<div class="reset-password-page">
  <div class="main-content">
    <div class="secondary-logo"></div>
    <div class="main-text">
      <p>BOOK, <br />MANAGE, <br />FLY</p>
    </div>
  </div>
  <div class="card-content">
    <div class="main-logo"></div>
    <div class="reset-password-container">
      <mat-card class="reset-password-card">
        <mat-card-content>
          <h1>RESET PASSWORD</h1>
          @if (!infoMessage) {
            <p>Enter your email to reset your password</p>
          }
          <form class="reset-password-form" #form="ngForm">
            @if (!infoMessage) {
              <label for="email" class="field-label">Email</label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-icon matPrefix svgIcon="email_icon"></mat-icon>
                <input
                  type="email"
                  matInput
                  [(ngModel)]="model.email"
                  name="email"
                  #email="ngModel"
                  (input)="clearInfoMessage()"
                  required
                  placeholder="Enter your email"
                />
                <button mat-icon-button matSuffix aria-label="Email icon">
                  <mat-icon svgIcon="help_icon"></mat-icon>
                </button>
                @if (email.invalid) {
                  <mat-error class="error-validation">
                    <mat-icon svgIcon="error_icon"></mat-icon>
                    Email address is required</mat-error
                  >
                }
              </mat-form-field>
            } @else {
              <p class="reset-instructions">
                The reset instructions are sent to the email address. Open your
                email and follow the instructions.
              </p>
            }

            @if (!infoMessage) {
              <div
                class="reset-password-links"
                fxLayout="row"
                fxLayoutAlign="space-between center"
              >
                <a
                  class="cancel-button"
                  [routerLink]="[loginPathConstants.LOGIN]"
                >
                  Cancel
                </a>

                <button class="reset-button" (click)="reset()" mat-flat-button>
                  Reset password
                  <mat-icon
                    iconPositionEnd
                    svgIcon="arrow_forward_icon"
                  ></mat-icon>
                </button>
              </div>
            } @else {
              <button
                class="login-button"
                (click)="goToLogin()"
                [disabled]="form.invalid"
                mat-flat-button
              >
                Go to login
                <mat-icon
                  iconPositionEnd
                  svgIcon="arrow_forward_icon"
                ></mat-icon>
              </button>
            }
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
